
.langMenuButton {
  min-height: 6em;
}

.btn-audio:active, .btn-audio:hover .play-btn-container svg {
  fill: white;
}

.btn-audio:active, .btn-audio:hover .play-btn-container svg g {
  stroke: white;
}

span.all-lng {
  cursor: pointer;
  display: inline-block;
  width: auto;
  box-shadow: 0px 1px rgba(207, 207, 207, .6);
}

span.all-lng:hover {
  color: #0033a0;
  box-shadow: none;
}

.all-lng:active {
  color: #c2c2c2;
}
.text-container {
  padding: 0 1vmax 0 1vmax;
}